import { Flex, For, IconFA, If, IntrinsicProps, Loader, createComponent, toClassName, useClickOutside } from 'react-commons';

import style from './index.module.scss';
import UsersModel from '@/lib/drupal/models/Users';
import useSWR from 'swr';
import { useAuthStore } from '@/lib/drupal/stores/auth';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useRef, useState } from 'react';
import useScreenSize from '@/lib/hooks/useScreenSize';

export interface FavoritesDrawerComponentProps extends IntrinsicProps {
  isOpen?: boolean
  setIsOpen: (isOpen: boolean) => void
}

const classStates = [
  'isOpen'
];

export default createComponent('FavoritesDrawer', { style, classStates }, ({ className }, props: FavoritesDrawerComponentProps) => {

  const [ maxTiles, setMaxTiles ] = useState(12);
  const showMore = useRef(false);

  const [ authState ] = useAuthStore();

  const { data: favoriteGames, isLoading } = useSWR(UsersModel.getUserFavoritesFetcherKey(authState?.user?.uid, 15, 0), UsersModel.userFavoritesFetcher);
  const { width: screenWidth } = useScreenSize();

  useEffect(() => {

    const gameCount = favoriteGames?.length ?? 0;

    if(screenWidth > 1366) {
      setMaxTiles(gameCount > 12 ? 11 : 12);
      showMore.current = gameCount > 12;
    } else if(screenWidth > 800) {
      setMaxTiles(gameCount > 8 ? 7 : 8);
      showMore.current = gameCount > 8;
    } else if(screenWidth > 600) {
      setMaxTiles(gameCount > 6 ? 5 : 6);
      showMore.current = gameCount > 6;
    } else {
      setMaxTiles(gameCount > 4 ? 3 : 4);
      showMore.current = gameCount > 4;
    }
  }, [ screenWidth, favoriteGames?.length ]);

  // Close when click outside
  const drawerRef = useRef(null);
  useClickOutside({
    ref: drawerRef,
    excludeRefs: [ { matches: '.DailyAppHeader__ToggleFavorites' } ]
  }, () => {
    props.setIsOpen(false);
  });

  if(!authState?.user?.uid) {
    return (<></>);
  }

  if(isLoading) {
    return (
      <Loader />
    );
  }

  const isEmpty = favoriteGames.length === 0;

  return (
    <div ref={drawerRef} className={className}>
      <div className={toClassName('FavoritesGridContainer', { isEmpty })}>
        <p className='FavoritesHeader --isLargeText'>Favorites</p>
        {
          If(isEmpty, () => (
            <div className='EmptyFavorites'>
              <p className='--isSmallText'>Add some games to your favorites and they'll show up here for quick access!</p>
            </div>
          )).Else(() => (
            <div className='FavoritesGrid'>
              {
                For(favoriteGames.slice(0, maxTiles), (game) => (
                  <a href={game.href} key={game.uid} className='FavoritesGrid__Game' style={{ backgroundImage: `url('${game.featuredImg}')` }} title={game.title}></a>
                ))
              }

              {
                If(showMore.current, () => (
                  <a className='FavoritesGrid__More' href={`/member/profiles/${authState.user.displayName}?tab=favorites`}>
                    <Flex alignCenter><span className='--isFontWeightBold --isLargeText'>View All</span><IconFA className='--marMd0__l' icon={faCircleArrowRight} /></Flex>
                  </a>
                )).EndIf()
              }
            </div>
          )).EndIf()
        }
      </div>
    </div>
  );

});