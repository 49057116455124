import axios from '@/lib/payments/api/axiosInstance';
import { AxiosResponse } from 'axios';
import { logErrorEvent } from '@/lib/util/logErrorEvent';
import formatMoney from '@/lib/util/formatMoney';
import { getZonedDate } from '@/lib/util/zonedTime';
import { getThumbnail } from '@/lib/drupal/models/Games';

interface SubscribeResponse {
  status: number
  success: boolean
  message: string
}

export type PlanInterval = 'monthly' | 'yearly';

export interface Plan {
  sku: string
  title: string
  planTitle: string
  description: string
  billingDisclaimer: string
  price: string
  monthlyPrice: string
  discountedPrice: string
  duration: number
  coupon: Coupon | null
}

export interface Coupon {
  identifier: string
  disclaimer: string
  discount: string
  ftu: boolean
  active: boolean
  valid: boolean
  startDate: [number, number, number]
  endDate: [number, number, number]
  promoDescription: string | null
  titleOverride: string | null
  welcomeOffer: string | null
  expiryText: string | null
}

export interface ShopKeeperPurchasedGameData {
  productId: number
  orderDate: string
  price: number
  sku: string
  title: string
  imageUrl: string | null
  orderItemId: number | null

  gameDetails: {
    view_node: string
    field_description?: string
    field_short_description: string
    field_medium_description?: string
    field_mobile_description_short?: string
    field_keyword_regular?: string
    field_keyword_small?: string
    field_keyword_large?: string
    field_keyword_xl?: string
    sw_game_download_url?: string
  } | null
  
}

export interface PurchasedGameData {
  // shopkeeper fields
  productId: string
  purchaseDate?: number
  price?: string
  orderItemId?: string
  downloadUrl?: string

  // drupal fields
  href: string
  title: string
  description: string
  descriptionShort: string
  keyword: string
  thumbnailImg: string
  thumbnailImgTall: string
  thumbnailImgSmall: string
  thumbnailImgXl?: string
  featuredImg?: string
}

interface PaymentInformationResponse {
  success: boolean
  message: string
  statusCode: string
  data: {
    firstName: string
    lastName: string
    postalCode: string
    ccType: string
    ccLastFour: string
    ccExpMonth: number
    ccExpYear: number
  }
}

export interface PaymentInformation {
    firstName: string
    lastName: string
    cardType: string
    lastFour: string
    expiryDate: string
}

const normalizePrice = (priceRaw: number | string) => {
  if(priceRaw === undefined || priceRaw === null) {
    return '';
  }
  
  const priceNumber = typeof priceRaw === 'string' ? Number.parseFloat(priceRaw) : priceRaw;
  return priceNumber.toFixed(2);
};

const transformSkuToKeyword = (sku: string): string => {
  if(sku.slice(-3) === '-pc') {
    return sku.slice(0, -3);
  }

  if(sku.slice(-4) === '-mac') {
    return sku.slice(0, -4);
  }

  return sku;
};

export default class SubscriptionModel {
  static async subscribe (data: {
    drupalId?: number
    memberId?: number
    password?: string
    countryCode?: string
    firstName: string
    lastName: string
    email: string
    username: string
    sku: string
    postalCode: string
    recurlyTokenId: string
    fieldOptIn?: boolean
    fieldTheme?: string
  }): Promise<SubscribeResponse> {
    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'post',
        url: '/v1/subscriptions',
        data
      });
    } catch (error) {
      logErrorEvent('Subscribe to Plan', false, error);
      throw error;
    }

    return response.data;
  }

  static async subscribeViaApplePay (token: string): Promise<SubscribeResponse> {
    // TODO
    return;
  }

  static async subscribeViaGooglePay (token: string): Promise<SubscribeResponse> {
    // TODO
    return;
  }

  static async subscribeViaPayPal (token: string): Promise<SubscribeResponse> {
    // TODO
    return;
  }

  static async cancelSubscription (memberId: number, subscriptionId: string, paymentUuid: string) {
    let response: AxiosResponse;
    try {
      response = await axios.post('/v1/subscriptions/cancel', {
        memberId,
        subscriptionId,
        paymentUuid,
      });
    } catch (error) {
      logErrorEvent('Cancel Subscription', false, error);
      throw error;
    }

    return response.data;
  }

  static async getPlanDetails (sku: string): Promise<Plan> {
    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'get',
        url: `/v1/ecomm/plan/${sku}`
      });
    } catch (error) {
      logErrorEvent('Get Plan Details', false, error);
      throw error;
    }

    return {
      ...response.data,
      price: normalizePrice(response.data.price),
      discountedPrice: normalizePrice(response.data.discountedPrice),
      monthlyPrice: normalizePrice(response.data.monthlyPrice),
      coupon: response.data.coupon ? {
        ...response.data.coupon,
        discount: normalizePrice(response.data.coupon.discount)
      } : null
    };
  }

  static async getPurchasedGames (memberId: string, limit?: number, page?: number): Promise<[ PurchasedGameData[], { totalPages: number, currentPage: number } ]> {
    let response: AxiosResponse;

    const params = [];

    if(limit !== undefined && limit > 0) {
      params.push(`size=${limit}`);
      params.push('');
    } else {
      params.push('size=10');
    }

    if(page !== undefined && page > -1) {
      params.push(`page=${page}`);
    } else {
      params.push('page=0');
    }

    try {
      response = await axios.request({
        method: 'get',
        url: `/v1/ecomm/members/${memberId}/purchases?${params.join('&')}`
      });
    } catch (error) {
      logErrorEvent('Get purchased games', false, error);
      throw error;
    }

    const paging = {
      totalPages: 0,
      currentPage: page
    };

    if(!Array.isArray(response?.data?.data?.content)) {
      return [ [], paging ];
    }
 
    const purchasedGames = response.data.data.content.map((rawData: ShopKeeperPurchasedGameData): PurchasedGameData => {

      const purchaseDate = Array.isArray(rawData.orderDate) ? new Date(rawData.orderDate.join('/')) : '';

      const purchaseDateTimestamp = (purchaseDate instanceof Date && !isNaN(purchaseDate.getTime())) ? purchaseDate.getTime() : undefined;

      const purchasedGameData = {
        productId: rawData.productId.toString(),
        purchaseDate: purchaseDateTimestamp,
        price: formatMoney(rawData.price),
        orderItemId: (rawData.orderItemId ?? '').toString(),

        // drupal fields
        title: rawData.title,
        keyword: transformSkuToKeyword(rawData.sku),
        href: `/gamelanding/${transformSkuToKeyword(rawData.sku)}`,
        thumbnailImg: getThumbnail(rawData.gameDetails?.field_keyword_regular, 'regular'),
        thumbnailImgTall: getThumbnail(rawData.gameDetails?.field_keyword_large, 'tall'),
        thumbnailImgSmall: getThumbnail(rawData.gameDetails?.field_keyword_small, 'small'),
        description: rawData.gameDetails?.field_description || rawData.gameDetails?.field_medium_description || rawData.gameDetails?.field_short_description || '',
        descriptionShort: rawData.gameDetails?.field_mobile_description_short || rawData.gameDetails?.field_short_description || '',
        thumbnailImgXl: getThumbnail(rawData.gameDetails?.field_keyword_xl, 'xl'),
        downloadUrl: rawData.gameDetails?.sw_game_download_url
      };

      return purchasedGameData;
    });

    paging.totalPages = response.data.data.totalPages;

    return [ purchasedGames, paging ];

  }

  static async resendGameKey (orderItemId: string, memberId: string, email: string): Promise<boolean> {
    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'post',
        url: '/v1/ecomm/members/purchases/keySend',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          itemId: orderItemId,
          memberId: memberId,
          email: email
        })
      });
    } catch (error) {
      logErrorEvent('Send game key', false, error);
      throw error;
    }

    return true;
  }

  static async reactivateSubscription (memberId: number, paymentUuid?: string, subscriptionId?: string): Promise<boolean> {
    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'put',
        url: '/v1/subscriptions/reactivate',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          memberId, 
          subscriptionId: paymentUuid ? undefined : subscriptionId, 
          paymentUuid
        })
      });
    } catch (error) {
      logErrorEvent('Reactivate Subscription', false, error);
      throw error;
    }

    return true;
  }

  static async getPaymentInformation (memberId: string): Promise<PaymentInformation | undefined> {
    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'get',
        url: `/v1/accounts/${memberId}/billingInfo`
      });
    } catch (error) {
      logErrorEvent('Get payment details', false, error);
      throw error;
    }

    const responseData = response.data as PaymentInformationResponse;

    if(responseData.statusCode !== 'OK' || !responseData.success) {
      return;
    }

    return {
      firstName: responseData.data.firstName,
      lastName: responseData.data.lastName,
      cardType: responseData.data.ccType.toLowerCase(),
      lastFour: responseData.data.ccLastFour,
      expiryDate: `${responseData.data.ccExpMonth.toString().padStart(2, '0')}/${responseData.data.ccExpYear}`
    };
  }

  static async updatePaymentInformation ( data ): Promise<boolean> {
    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'put',
        url: `/v1/accounts/${data.memberId}`,
        data
      });
    } catch (error) {
      logErrorEvent('Manage payment details', false, error);
      throw error;
    }

    return true;
  }
};