import axios from 'axios';

const instance = axios.create();

// Defaults
instance.defaults.baseURL = process.env.NEXT_PUBLIC_PAYMENTS_URL + '/api';
instance.defaults.withCredentials = true;

export default instance;

export const jsonApiHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};
