import { createComponent, If, IntrinsicProps } from 'react-commons';

import style from './index.module.scss';
import Link from 'next/link';
import { CaptureClickEvent } from '@/lib/util/captureClickEvent';
import { useAnnualPlanPromo } from '@/lib/util/useAnnualPlanPromo';
import { useCallback, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

interface PromoAppBannerProps extends IntrinsicProps {
  eventAction: string
  eventTagName: string
  eventLocation?: string
  useResize?: boolean
}

function onResize (node: HTMLElement) {
  setTimeout(() => {
    const promoHeight = node?.getBoundingClientRect().height || 0;

    const appMainEl = document.querySelector('.App__Main') as HTMLDivElement;
    if (appMainEl) {
      appMainEl.style.marginTop = `${promoHeight + 48}px`;
    };

    const dailySidebarEl = document.querySelector('.DailySidebarMenu') as HTMLDivElement;
    if (dailySidebarEl) {
      dailySidebarEl.style.top = `${promoHeight + 48}px`;
    }

    const asideEl = document.querySelector('.FluidLayout__Aside') as HTMLDivElement;
    if (asideEl) {
      asideEl.style.top = `${promoHeight + 64}px`;
    }
  }, 10);
};

export default createComponent<PromoAppBannerProps>('PromoAppBanner', { style }, function PromoAppBanner ({ className }, props) {
  const router = useRouter();
  const { isActive, promoDescription } = useAnnualPlanPromo();
    
  const ref = useCallback((node) => {
    if (!props.useResize) return;
    onResize(node);
  }, [ props.useResize ]);

  useEffect(() => {
    const resize = () => {
      if (!props.useResize) return;
      onResize(document.querySelector('.PromoAppBanner') as HTMLElement);
    };
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [ props.useResize, router.asPath ]);
  
  return (
    <>
      {
        If(isActive && promoDescription, () => (
          <CaptureClickEvent
            action={props.eventAction}
            location={props.eventLocation || ''}
            className={className}
            properties={() => ({
              tag_name: props.eventTagName,
            })}
            href='/unlimited'
          >
            <Link href='/unlimited'>
              <div 
                className={className} 
                ref={ref}
              >
                <p>
                  <span dangerouslySetInnerHTML={{ __html: promoDescription }} />&nbsp;
                  <a>Learn More</a>
                </p>
              </div>
            </Link>
          </CaptureClickEvent>
        )).EndIf()
      }
    </>
  );
});
