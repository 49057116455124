import { useEffect, useState } from 'react';

import { useAuthStore } from '@/lib/drupal/stores/auth';
import { appActions, useAppStore } from '@/lib/stores/app';
import SubscriptionModel, { Coupon } from '@/lib/payments/models/SubscriptionModel';

export function useAnnualPlanPromo (): {
  isActive: boolean,
  coupon: Coupon | null,
  promoDescription: string,
  } {
  const [ appState ] = useAppStore();
  const [ authState ] = useAuthStore();
  const [ isActive, setIsActive ] = useState(false);

  useEffect(() => {
    setIsActive((
      !authState.user?.isPremiumUser &&
      appState.promos.annualPlan?.coupon?.valid
    ));
  }, [
    authState.user?.isPremiumUser,
    appState.promos.annualPlan?.coupon?.valid, 
  ]);

  const coupon = appState.promos.annualPlan?.coupon || null;

  const promoDescription = 
    (
      !authState.user?.isPremiumUser &&
      appState.promos.annualPlan?.coupon?.valid &&
      appState.promos.annualPlan?.coupon?.promoDescription
    ) || '';

  return {
    isActive,
    coupon,
    promoDescription,
  };
}

export function useFetchAnnualPlanPromo () {
  const [ appState, appDispatch ] = useAppStore();
  const [ authState ] = useAuthStore();

  useEffect(() => {
    if (!authState.ready) return;
    if (appState.promos?.annualPlan) return;
    if (authState.user?.isPremiumUser) return;

    (async () => {
      const plan = await SubscriptionModel.getPlanDetails('swuannual5940');

      if (plan.coupon.valid) {
        appDispatch(appActions.setAnnualCoupon(plan));
      }
    })();
  }, [ appDispatch, appState.promos.annualPlan, authState.user, authState.ready ]);
}
